@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noto Sans";
  src: url("/public/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("/public/fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "arbie";
  src: url("/public/fonts/arbie.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

table {
  border-spacing: 0;
}

html {
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  scrollbar-color: #7e59f2 white;
  scrollbar-color: var(--Dialer2Purple) white;
  scrollbar-width: auto;
}

/* Chrome, Safari, Edge, Opera - Input number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome - Input border */
*,
*:focus,
*:hover {
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #7e59f2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: #cecfd0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #7e59f2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
